import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    headerContainer: {
        background: '#ecf0f9',
        width: 'calc(100% + 0px)',
        height: '40px',
        maxWidth: '480px',
        display: 'flex',
        alignItems: 'center',
        paddingTop: '10px',
        margin: '0',
        // position: 'absolute',
        // top: 0, left: 0
    },
    backBtn: {
        width: '20px',
        height: '20px',
        paddingLeft: 10,
        margin: 0,
        border: 0,
        background: 'transparent',
        cursor: 'pointer',
        display: 'block',
        position: 'absolute',
        top: 10,
        left: 'auto',
    },
    backBtnIcon: {
        width: '20px',
        height: '20px',
        color: '#c0c0c0'
    },
    headerTitle: {
        color: '#1b3f57',
        fontSize: '20px',
        fontWeight: 500,
        margin: '0px auto',
        fontFamily: 'canaroBook',
        textAlign: 'center',
    },
    soundBtn: {
        width: '20px',
        height: '20px',
        padding: 0,
        margin: 0,
        border: 0,
        background: 'transparent',
        cursor: 'pointer',
        display: 'block'
    },
    soundBtnIcon: {
        width: '20px',
        height: '20px',
        color: '#c0c0c0'
    },
    winnerBtn: {
        width: '26px',
        height: '26px',
        padding: 0,
        margin: 0,
        border: 0,
        background: 'transparent',
        cursor: 'pointer',
        display: 'block'
    },
    winnerBtnIcon: {
        width: 26,
        height: 26,
        color: '#c0c0c0',
        objectFit: 'contain'
    },
    quitContainer: {
        display: "flex",
        position: 'absolute',
        top: 5,
        right: 10,
        border: '1px solid #d0d0d0',
        width: 70,
        height: 30,
        borderRadius: 15,
        justifyContent: 'center',
        alignItems: 'center'  ,
        cursor: 'pointer'
    },
    quit: {
        marginTop: '15px',
        fontSize: 12,
        color: 'white',
    },
    leftContainer: {
        display: "flex",
        position: 'absolute',
        top: 5,
        left: 10,
        border: '1px solid #d0d0d0',
        width: 70,
        height: 30,
        borderRadius: 15,
        justifyContent: 'center',
        alignItems: 'center'  ,
        cursor: 'pointer'
    },
    left: {
        marginTop: '15px',
        fontSize: 12,
        color: 'white',
    },
    rightContainer: {
        display: "flex",
        position: 'absolute',
        top: 10,
        right: 10,
        // border: '1px solid #d0d0d0',
        width: 70,
        height: 30,
        borderRadius: 15,
        justifyContent: 'center',
        alignItems: 'center'  ,
        cursor: 'pointer'
    },
    right: {
        marginTop: '15px',
        fontSize: 14,
        fontWeight: 'bold',
        color: '#00599d',
    },
    dagdaganBtnIcon: {
        width: '96px',
        height: '48px',
        color: '#c0c0c0',
        objectFit: 'contain'
    },
    dagdaganBtn: {
        width: '96px',
        height: '48px',
        padding: 0,
        margin: 0,
        border: 0,
        background: 'transparent',
        cursor: 'pointer',
        display: 'block'
    },
}));
