import { makeStyles } from "@material-ui/core/styles";
import { isMobile } from "react-device-detect";

export default makeStyles((theme) => ({
  headerContainer: {
    position: "absolute",
    maxWidth: isMobile ? '480px' : "500px",
    width: "100%",
    top: "0px",
    height: '100px',
    display: "flex",
    // zIndex: 4,
    zIndex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    transform: "translateX(-50%)",
    left: "50%",
    backgroundColor: 'transparent',
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
    },
  },
  profileContainer: {
    display: "flex",
    position: 'absolute',
    top: 7,
    left: 10,
    justifyContent: 'center',
    alignItems: 'center'
  },
  notifContainer: {
    display: "flex",
    position: 'absolute',
    top: 7,
    right: 10
  },
  balanceContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: "auto",
    marginBottom: "auto",
    justifyContent: "flex-start",
  },
  buyContainer: {
    display: "flex",
    position: 'absolute',
    top: 10,
    right: 10,
    border: '1px solid #d0d0d0',
    width: 70,
    height: 30,
    borderRadius: 15,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  },
  buy: {
    marginTop: '15px',
    fontSize: 12,
    color: 'white',
  },
  notif: {
    width: 40,
    height: 40,
    borderRadius: '50%',
    cursor: 'pointer',
  },
  storeContainer: {
    display: "flex",
    position: 'absolute',
    top: 7,
  },
  store: {
    width: 40,
    height: 40,
    borderRadius: '50%',
    cursor: 'pointer',
  },
}));
