import Phone from '../assets/images/common-images/phoneCall.png';
import Messenger from '../assets/images/common-images/messenger.png';

export const footerMenus = [
  {
    label: 'CALL',
    icon: Phone
  },
  {
    label: 'MESSENGER',
    icon: Messenger
  },
];
